$font:'TeXGyreAdventor';
$white:#ffffff;
$yellow:#dcb940;
$black:#000000;

@mixin fill{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

ul.four_elements > li{width: 25%!important;}

.container2 {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container2 {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container2 {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container2 {
    width: 1170px;
  }
}

@media (min-width: 1500px) {
  .container2 {
    width: 1620px;
  }
}

div.vertical_height{
	@include fill;
	> div{
		display: table;
		width: 100%;
		height: 100%;
		> div{
			display: table-cell;
			vertical-align: middle;
			height: 100%;
		}
	}
}

a.all_click{
	@include fill;
}

a.more1{
	display: inline-block;
	padding-right: 50px;
	padding-top: 10px;
	padding-bottom: 10px;
	font-size: 12px;
	text-transform: uppercase;
	color: $white;
	background-position: right center;
	background-repeat: no-repeat;
	background-image: url('./../img/more_arr.png');
}

a.more2{
	font-size: 14px;
	text-transform: uppercase;
	font-size: 14px;
	padding: 25px 67px;
	border: 4px solid $yellow;
	color: $black;
	font-weight: bold;
	display: inline-block;
}

a.more3{
	font-size: 11px;
	display: inline-block;
	padding: 33px 83px 33px 31px;
	background-color: #1a1a1a;
	background-position: right 33px center;
	background-repeat: no-repeat;
	background-image: url('./../img/more_arr.png');
	color: #e8e8e8;
	font-weight: bold;
}

div.default_font{
	font-size: 14px;
	line-height: 24px;
	h1{
		font-size: 70px;
	}
	h2{
		font-size: 45px;
		line-height: 54px;
	}
	h3{
		font-size: 36px;
		line-height: 44px;
	}
	h4{
		font-size: 30px;
		line-height: 36px;
	}
	h5{
		font-size: 20px;
	}
	h6{
		font-size: 18px;
		line-height: 30px;
	}
	strong{
		font-weight: bold;
	}
	ul{
		list-style: disc;
		padding-left: 20px;
	}
	ol{
		padding-left: 20px;
	}
}

div.text_desc{
	div.default_font{
		h1,h2,h3,h4,h5,h6,p,ul,ol,a,p{
			padding-bottom: 30px;
			&:last-child{
				padding-bottom: 0;
			}
		}
		img{
			max-width: 100%;
			height: auto;
			margin-bottom: 30px;
		}
	}
}

body{
	font-family: $font;
	ul{
		list-style: none;
		margin: 0;
		padding: 0;
	}
	h1,h2,h3,h4,h5,h6,p,ol,a{
		margin: 0;
	}
	a{
		text-decoration: none;
		&:hover{
			text-decoration: none;
		}
	}
	//background-color: rebeccapurple;
}

div.page{
	position: relative;
	overflow: hidden;
}

header.header{
	padding-top: 30px;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
	div.logo_box{
		position: absolute;
		left: -95px;
		 top: 0;
		z-index: 6;
	}
	div.header_right{
		padding-top: 100px;
		border-bottom: 4px solid $yellow;
		padding-bottom: 17px;
		position: relative;
		div.langs_social{
			> ul{
				> li{
					display: inline-block;
					vertical-align: middle;
					div.dropdown{
						margin-left: 47px;
						padding-right: 47px;
						display: inline-block;
						button.btn-primary{
							font-size: 19px;
							font-weight: bold;
							border: none;
							background-color: transparent;
							padding: 0;
							&:focus{
								background-color: transparent;
							}
						}
						.dropdown-menu{
							background-color: $yellow;
							min-width: 0;
							padding: 5px;
							border: none;
							> li{
								> a{
									font-size: 19px;
									font-weight: bold;
									padding: 0;
									color: $white;
									&:hover{
										background-color: $black;
									}
								}
							}
						}
					}	
					div.social{
						> ul{
							> li{
								display: inline-block;
								vertical-align: top;
								padding-left: 19px;
								margin-left: 19px;
								position: relative;
								&:before{
									content: '';
									position: absolute;
									top: 0;
									left: 0;
									height: 100%;
									border-left: 1px solid $yellow;
									display: block;
								}
								&:first-child{
									padding-left: 0;
									margin-right: 0;
									&:before{
										display: none;
									}
								}
							}
						}
					}
				}
			}
		}
		div.right_holder{
			text-align: right;
			position: relative;
			nav.menu{
				> ul{
					> li{
						display: inline-block;
						vertical-align: top;
						margin-left: -4px!important;
						padding-right: 22px;
							margin-right: 17px;
							position: relative;
							&:after{
								content: '';
								display: block;
								width: 5px;
								height: 0;
								padding-bottom: 5%;
								position: absolute;
								top: 12px;
								right: 0;
								background-repeat: no-repeat;
								background-position: center;
								background-image: url('./../img/menu_dot.png');
							}
						a{
							color: $white;
							font-size: 15px;
							//padding-right: 22px;
							//margin-right: 17px;
							position: relative;
							&:after{
								content:"";
								display:block;
								width:0%;
								position: absolute;
								left:0%;
								border-bottom:4px solid #fff;
								transition:0.3s ease;
								-o-transition:0.3s ease;
								-webkit-transition:0.3s ease;
								-moz-transition:0.3s ease;
								-ms-transition:0.3s ease;
								top:210%;
							}
							&:hover{
								&:after{
								left:0%;
								width:100%;
								}
							}
						}
						&:last-child{
							&:after{
								display: none;
							}
						}
						div.on{
							a{
								&:after{
									width: 100%;
								}
							}
						}
					}
				}
			}
			.header_contact{
				position: absolute;
				top: -24px;
				right:-66px;
				> a{
					border-top: 4px solid $yellow;
					border-left: 4px solid $yellow;
					border-right: 4px solid $yellow;
					border-bottom: 4px solid $yellow;
					display: inline-block;
					padding: 22px 17px;
				}
			}
		}
	}
}

.partners {
	padding-top:30px;
	padding-bottom:100px;
}

section.slider{
	height: 100vh;
	width: 100%;
	position: relative;
	background-color: #190e02;
	div.slides{
		width: 100%;
		height: 0;
		padding-bottom: 95vh;
		position: relative;
		&:before{
			content: '';
			display: block;
			border-left: 4px solid $yellow;
			height: 100%;
			position: absolute;
			bottom: -149px;
			left: 0;
			z-index: 5;
		}
		&:after{
			content: '';
			display: block;
			border-right: 4px solid $yellow;
			height: 100%;
			position: absolute;
			top: 149px;
			right: 0;
			z-index: 5;
		}
		> ul{
			@include fill;
			/*&:after{
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				border-left: 4px solid $yellow;
				border-right: 4px solid $yellow;
				border-bottom: 4px solid $yellow;
				position: absolute;
				height: 100%;
				width: 100%;
				margin-top: 149px;
				z-index: 5;
			}*/
			> li{
				@include fill;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				background-attachment: fixed;
			}
		}
	}
	div.slider_content{
		@include fill;
		div.slider_text{
			height: 125px;
			position: relative;
			margin-top: 10%;
			> ul{
				@include fill;
				> li{
					@include fill;
				}
			}
			div.default_font{
				color: $white;
				text-align: right;
				padding-right: 20%;
				position: relative;
				h1,h2,h3,h4,h5,h6,p{
					position: relative;
					&:after{
						content: '';
						display: block;
						width: 125px;
						height: 0;
						padding-bottom: 125px;
						display: block;
						position: absolute;
						right: -72px;
						bottom: -52px;
						background-repeat: no-repeat;
						background-position: center;
						background-image: url('./../img/slider_square.png');
					}
				}
			}
			div.slider_dots{
				position: absolute;
				width: 100%;
				top: 100%;
				left: 0;
				padding-right: 26%;
				> ul{
					text-align: right;
					> li{
						width: 11px;
						height: 0;
						padding-bottom: 11px;
						background-color: rgba(255,255,255,0.3);
						cursor: pointer;
						display: inline-block;
						vertical-align: top;
						margin-right: 11px;
						&:last-child{
							margin-right: 0;
						}
						&.on{
							background-color: $yellow;
						}
					}
				}
			}
		}
	}
	div.cover{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100px;
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url('./../img/fog11.png');
	}
	div.cover2{
		-moz-transform: scale(1, -1);
		-webkit-transform: scale(1, -1);
		-o-transform: scale(1, -1);
		-ms-transform: scale(1, -1);
		transform: scale(1, -1);
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100px;
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url('./../img/fog11.png');
	}
}

section.home_title{
	//padding: 3% 0;
	position: relative;
	div.title_holder{
		border-left: 4px solid $yellow;
		padding: 5% 0;
		background-position: left -6px center;
		background-repeat: no-repeat;
		//background-image: url('./../img/square_lines2.jpg');
		ul{
			li{
				display: inline-block;
				vertical-align: middle;
				&:last-child{
					width: 72%;
				}
				div.default_font{
					padding-left: 40px;
				}
				img{
					max-width: 100%;
					height: auto;
					position: relative;
					left: -4px;
				}
			}
		}
		&.no_border{
			//border: none;
			border-right: 4px solid $yellow;
			padding-left: 50px;
			position: relative;
			margin-bottom: 50px;
			&:before{
				content: '';
				display: block;
				position: absolute;
				left: 0;
				bottom: 0;
				border-bottom: 4px solid $yellow;
				width: 140px;
			}
			&:after{
				content: '';
				display: block;
				position: absolute;
				right: 0;
				bottom: 0;
				border-bottom: 4px solid $yellow;
				width: 140px;
			}
		}
		&.contact_title{
			border-right: 4px solid $yellow;
			padding-left: 50px;
			position: relative;
			margin-bottom: 50px;
			&:before{
				content: '';
				display: block;
				position: absolute;
				left: 0;
				top: 98%;
				height: 66px;
				background-repeat: no-repeat;
				background-position: left top;
				background-image: url('./../img/contact_border_left.png');
				width: 147px;
			}
			&:after{
				content: '';
				display: block;
				position: absolute;
				right: 0;
				top: 98%;
				height: 66px;
				background-repeat: no-repeat;
				background-position: right top;
				background-image: url('./../img/contact_border_right.png');
				width: 147px;
			}
		}
		/*&.participants_desc{
			padding: 0;
			div.text_desc{
				position: relative;
				top: 50px;
				div.default_font{
					color: #a9a9a9;
					h5{
						line-height: 35px;

					}
				}
			}
		}*/
		div.text_desc{
			padding-top: 20px;
		}
	}
	div.cover{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100px;
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url('./../img/fog11.png');
	}
	div.hand_holder{
		position: absolute;
		bottom: 50px;
		right: 0;
		z-index: 10;
		width: 690px;
		left: 66%;
		img{
			max-width: 100%;
			height: auto;
		}
	}
	div.title_holder2{
		margin-top: 27px;
		border-top: 4px solid $yellow;
	}
	div.container{
		position: relative;
		z-index: 2;
	}
	/*&:before{
		content: '';
		display: block;
		width: 100%;
		height: 97px;
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;
		background-image: url('./../img/fog11.png');
		position: absolute;
		bottom: 100%;
		left: 0;
		background-size: cover;
	}*/
	&:after{
		content: '';
		display: block;
		width: 100%;
		height: 104px;
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;
		background-image: url('./../img/fog22.png');
		position: absolute;
		top: 100%;
		left: 0;
		background-size: 100% auto;
		z-index: 5;
	}
	div.default_font{
		background-color: $white;
		position: relative;
		z-index: 6;
		padding-right: 43px;
	}
	&.no_fog{
		&:after{
			display: none;
		}
	}
	div.date{
		div.default_font{
			padding-top: 10px;
		}
	}
}

header.section_header{
	padding-left: 50px;
	> div.default_font{
		padding-bottom: 23px;
		margin-bottom: 47px;
		position: relative;
		h1,h2,h3,h4,h5,h6{
			text-transform: uppercase;
		}
		&:after{
			content: '';
			display: block;
			width: 40px;
			left: 0;
			top: 100%;
			border-bottom: 9px solid $yellow;
			position: absolute;
		}
	}
	&.header_white{
		padding-left: 0;
		div.default_font{
			color: $white;
		}
		> div.place_and_date{
			padding-bottom: 10%;
			> div.default_font{
				padding-bottom: 33px;
				color: #a9a9a9;
				&:last-child{
					padding-bottom: 0;
				}
				strong{
					font-weight: normal;
					color: $yellow;
				}
			}
		}
	}
}

section.contest{
	position: relative;
	background-color: $black;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	div.contest_content_holder{
		&:after{
			content: '';
			display: block;
			height: 68px;
			width: 100%;
			border-right: 4px solid $yellow;
			border-top: 4px solid $yellow;
		}
		div.contest_holder{
			border-left: 4px solid $yellow;
			padding-left: 50px;
			padding-top: 30%;
			&.no_border{
				border: none;
				padding-bottom: 25%;
			}
		}
		&.no_border{
			&:after{
				display: none;
			}
		}
	}
	div.default_font{
		color: $white;
	}
	div.text_desc{
		div.default_font{
			padding-bottom: 30px;
			text-align: left;
			line-height: 30px;
		}
		a.more1{
			margin-bottom: 30px;
		}
	}
	div.cover{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100px;
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url('./../img/fog11.png');
	}
}

section.short_about{
	//padding: 10% 0;
	div.col-md-12{
		div.short_about_content{
			border-right: 4px solid $yellow;
			> div{
				display: inline-block;
				vertical-align: bottom;
				width: 50%;
				&.desc{
					> header{
						margin-left: 50px;
						padding-bottom: 26px;
						border-bottom: 4px solid $yellow;
						div.default_font{
							h1,h2,h3,h4,h5,h6{
								color: $yellow;
							}
						}
					}
					div.text_desc{
						border-right: 4px solid $yellow;
						padding-top: 26px;
						padding-bottom: 96px;
						padding-right: 130px;
						padding-left: 50px;
					}
				}
				&.image{
					border-bottom: 4px solid $yellow;
					height: 0;
					padding-bottom: 629px;
					position: relative;
					> img{
						max-width: 100%;
						position: absolute;
						bottom: 54px;
						left: -70px;
					}
				}
			}
		}
	}
}

section.news{
	padding: 0;
	a.more2{
		margin-right: 50px;
	}
	article{
		position: relative;
		margin-bottom: 30px;
		&:hover{
			div.image{
				div.vertical_height{
					opacity: 1;
				}
			}
		}
		div.image{
			//height: 247px;
			padding-top: 75%;
			height: 0;
			width: 100%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			position: relative;
			div.vertical_height{
				transition: 0.3s all ease;
				background-color: rgba(0,0,0,0.7);
				opacity: 0;
				p{
					font-size: 11px;
					padding-top: 5px;
					padding-bottom: 5px;
					padding-right: 50px;
					background-position: right center;
					background-repeat: no-repeat;
					background-image: url('./../img/more_arr2.png');
					display: inline-block;
					color: $white;
					text-transform: uppercase;
				}
			}
		}
		div.desc{
			padding: 30px;
			background-color: #f6f6f6;
			header{
				padding-bottom: 10px;
				div.default_font{
					//text-transform: uppercase;
				}
			}
			div.date{
				padding-top: 20px;
			}
			div.default_font{
				line-height: 30px;
			}
			a.more1{
				color: $black;
			}
		}
	}
	&.no_padding_top{
		padding-top: 0;
		padding-bottom: 0;
	}
	div.load_more{
		padding-top: 15px;
		padding-bottom: 50px;
	}
	ul{
		li{
			width: 23%;
			display: inline-block;
			vertical-align: top;
			margin-right: 2.4%;
			margin-bottom: 2.4%;
			&:nth-child(4n){
				margin-right: 0;
			}
		}
	}
}

section.partners{
	div.carousel1{
		overflow: hidden;
		> ul{
			position: relative;
			white-space: nowrap;
			> li{
				display: inline-block;
				vertical-align: top;
				width: 33.33%;
				white-space: normal;
			}
		}
	}
	div.image{
		width: 100%;
		height: 0;
		padding-bottom: 82%;
		position: relative;
		background-size: 60% auto;
		background-position: center;
		background-repeat: no-repeat;
		div.partners_cover{
			text-align: center;
			@include fill;
			background-color: $black;
			z-index: 2;
			opacity: 0;
			transition: 0.3s all ease;
			div.default_font{
				padding: 0 15px;
				margin-bottom: 30px;
				text-align: center;
				color: $white;
				h1,h2,h3,h4,h5,h6,p{
					padding-bottom: 19px;
					position: relative;
					&:after{
						content: '';
						display: block;
						border-bottom: 8px solid #785d3c;
						width: 39px;
						position: absolute;
						top: 100%;
						left: 0;
						right: 0;
						margin-left: auto;
						margin-right: auto;
						transition: 0.3s all ease;
					}
				}
				h3{
					font-size: 25px;
					line-height: 35px;
				}
			}
		}
		a.all_click{
			z-index: 5;
		}
		&:hover{
			div.partners_cover{
				opacity: 1;
			}
		}
	}
}

section.home_bottom{
	padding: 5% 0;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	background-attachment: fixed;
	div.default_font{
		margin-top: 3%;
		border-right: 4px solid $yellow;
		border-bottom: 4px solid $yellow;
		padding: 15% 15% 15% 0;
		color: $white;
		position: relative;
		&:before{
			content: '';
			display: block;
			height: 50%;
			left: 0;
			bottom:0;
			position: absolute;
			border-left: 4px solid $yellow;
		}
		&:after{
			content: '';
			display: block;
			width: 65%;
			right: 0;
			top:0;
			position: absolute;
			border-top: 4px solid $yellow;
		}
		h1,h2,h3,h4,h5,h6,p{
			position: relative;
			&:after{
				content: '';
				display: block;
				width: 125px;
				height: 0;
				padding-bottom: 125px;
				display: block;
				position: absolute;
				right: -72px;
				bottom: -52px;
				background-repeat: no-repeat;
				background-position: center;
				background-image: url('./../img/slider_square.png');
			}
		}
		&.no_border2{
			border-right: none;
			border-bottom: none;
			&:before{
				display: none;
			}
			&:after{
				display: none;
			}
		}
	}
	div.cover{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100px;
		background-position: center top;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url('./../img/fog22.png');
	}
}

footer.footer{
	div.footer_top{
		padding: 6% 0;
		position: relative;
		div.container{
			position: relative;
			z-index: 2;
			div.default_font{
				h2{
					padding-bottom: 10px;
					color: $white;
				}
				h6{
					color: #a9a9a9;
				}
			}
		}
		div.footer_bg{
			position: absolute;
			top: 0;
			height: 100%;
			&.black{
				left: 0;
				width: 57%;
				background-color: $black;
			}
			&.dark{
				left: 57%;
				width: 43%;
				background-color: #262629;
			}
		}
		div.holder{
			border: 6px solid $white;
			background-color: $white;
			margin-left: 6%;
			input{
				&.input{
					height: 115px;
					width: 80%;
					border: none;
					outline: none;
					font-family: $font;
					font-size: 18px;
					color: #7f7f80;
					padding-left: 48px;
					padding-top: 0;
					padding-bottom: 0;
				}
				&.submit{
					height: 114px;
					border: none;
					background-color: #262629;
					font-size: 15px;
					font-weight: bold;
					font-family: $font;
					color: #7f7f80;
					width: 20%;
					padding: 0;
				}
			}
		}
	}
	div.footer_bottom{
		background-color: #262629;
		padding: 3% 0;
		div.col-md-4{
			&.text-left{
				div.default_font{
					color: #cfcfce;
				}
			}
			&.text-center{
				p{
					display: inline-block;
					font-size: 11px;
					text-transform: uppercase;
					color: $white;
					font-weight: bold;
					padding: 10px 45px 10px 0;
					background-repeat: no-repeat;
					background-position: right center;
					background-image: url('./../img/arr_top.png');
					cursor: pointer;
				}
			}
			&.text-right{
				ul{
					> li{
						display: inline-block;
						vertical-align: middle;
						div.default_font{
							color: #cfcfce;
							padding-right: 27px;
						}
					}
				}
			}
		}
	}
}

section.top{
	width: 100%;
	position: relative;
	background-color: #190e02;
	div.top_bg{
		height: 500px;
		width: 100%;
		background-position: center;
		background-repeat: no-repeat;
		position: relative;
		&:before{
			content: '';
			display: block;
			border-left: 4px solid $yellow;
			height: 100%;
			position: absolute;
			bottom: -149px;
			left: 0;
			z-index: 5;
		}
		&:after{
			content: '';
			display: block;
			border-right: 4px solid $yellow;
			height: 100%;
			position: absolute;
			top: 149px;
			right: 0;
			z-index: 5;
		}
	}
	header.page_header{
		padding-top: 285px;
		div.default_font{
			text-align: center;
			color:$white;
			h6{
				color: $yellow;
			}
		}
	}
	div.cover{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100px;
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url('./../img/fog11.png');
	}
	div.cover2{
		-moz-transform: scale(1, -1);
		-webkit-transform: scale(1, -1);
		-o-transform: scale(1, -1);
		-ms-transform: scale(1, -1);
		transform: scale(1, -1);
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100px;
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url('./../img/fog11.png');
	}
}

section.cooks{
	padding:5% 0 0 0;
	div.container{
		> div.row{
			> div.col-md-12{
				> header.section_header{
					border-bottom: none;
					div.default_font{
						h1,h2,h3,h4,h5,h6{
							color: $black;
						}
					}
				}
			}
		}
	}
	div.cooks_box{
		margin-bottom: 120px;
	}
	header{
		border-bottom: 4px solid $yellow;
		div.default_font{
			padding-bottom: 20px;
			display: inline-block;
			text-align: left;
			h1,h2,h3,h4,h5,h6{
				color: $yellow;
			}
		}
	}
	div.desc{
		border-right: 4px solid $yellow;
		padding-top: 20px;
		padding-right: 20px;
		&.desc_right{
			border-right: none;
			border-left: 4px solid $yellow;
			padding-right: 0;
			padding-left: 20px;
			div.text_desc{
				padding-left: 20px;
				padding-right: 0;
				text-align: right;
				div.default_font{
					text-align: left;
				}
			}
			div.image{
				width: 44%;
				background-size: cover;
				background-position: center top;
				background-repeat: no-repeat;
			}
		}
		> div{
			display: inline-block;
			vertical-align: top;
			position: relative;
			
		}
		a.more1{
			color: $black;
			cursor: pointer;
			margin-top: 15px;
		}
	}
	div.image{
		width: 44%;
		background-size: cover;
		background-position: center top;
		background-repeat: no-repeat;
		height: 360px;
	}
	div.text_desc{
		width: 56%;
		padding-right: 20px;
		div.default_font{
			line-height: 30px;
		}
	}
}

section.download{
	header.section_header{
		div.default_font{
			margin-bottom: 0;
			border-bottom: 9px solid $yellow;
			&:after{
				display: none;
			}
		}
	}
	div.desc_links{
		padding-top: 40px;
		border-right: 9px solid $yellow;
		padding-right: 50px;
		padding-left: 50px;
		div.text_desc{
			padding-bottom: 40px;	
		}
		ul{
			li{
				display: inline-block;
				vertical-align: top;
				width: 22%;
				margin-right: 4%;
				margin-bottom: 2%;
				> a{
					padding-left: 53px;
					padding-top: 7px;
					padding-bottom: 3px;
					background-position: left center;
					background-repeat: no-repeat;
					background-image: url('./../img/download_icon.png');
					font-size: 24px;
					color: $black;
				}
			}
		}
	}
}

section.news_details{
	div.text_desc{
		padding-left: 50px;
		padding-right: 30px;
		div.default_font{
			h4{
				font-size: 24px;
			}
		}
		&.add_text{
			padding-top: 30px;
		}
	}
	div.image{
		text-align: right;
		img{
			max-width: 100%;
			height: auto;
		}
	}
}

section.contact{
	padding: 3% 0;
	div.contact_data{
		padding: 0 50px;
		> div.text_desc{
			padding-bottom: 30px;
		}
		> ul{
			li{
				div.default_font{
					h6{
						padding-left: 54px;
						background-repeat: no-repeat;
						background-position: left center;
					}
				}
				&.mail{
					padding-bottom: 30px;
					div.default_font{
						h6{
							background-image:url('./../img/c_icon1.png');
						}
					}
				}
				&.phone{
					div.default_font{
						h6{
							background-image:url('./../img/c_icon2.png');
						}
					}
				}
			}
		}
	}
	div.contact_form{
		padding: 0 50px;
		> div.default_font{
			padding-bottom: 38px;
		}
		form{
			div.holder{
				&.text-right{
					padding-top: 23px;
				}
				margin-bottom: 8px;
				input.input{
					border: 2px solid $yellow;
					height: 76px;
					width: 100%;
					padding-left: 27px;
					outline: none;
					font-family: $font;
					font-size: 18px;
				}
				textarea.textarea{
					border: 2px solid $yellow;
					height: 256px;
					width: 100%;
					padding-left: 27px;
					outline: none;
					font-family: $font;
					font-size: 18px;
					resize: none;
					padding-top: 29px;
				}
				input.submit{
					height: 30px;
					width: 230px;
					padding-right: 56px;
					background-repeat: no-repeat;
					background-position: right center;
					background-image: url('./../img/more_arr.png');
					background-color: transparent;
					border: none;
					font-size: 16px;
					font-family: $font;
				}
			}
		}
	}
}

@media only screen and (min-width: 1501px) and (max-width: 1600px) {
	.container{width: 1170px;}
	section.home_title div.title_holder ul li:first-child{width: 28%;}
}

@media only screen and (min-width: 1201px) and (max-width: 1500px) {
	section.home_title div.hand_holder{bottom: 10px;}
	section.home_title div.title_holder ul li:first-child{width: 28%;}
	
	header.header div.header_right div.right_holder nav.menu > ul > li a{font-size: 13px!important;}
	header.header div.header_right div.right_holder nav.menu > ul > li a:after{top: 233%!important;}
}

@media only screen and (min-width: 1200px) and (max-width: 1366px) {
	.container{width: 1000px;}
	header.header div.header_right div.right_holder nav.menu > ul > li{padding-right: 16px; margin-right: 16px;}
	header.header div.header_right div.right_holder nav.menu > ul > li > div a:after{top: 182%;}
	header.header div.header_right div.right_holder nav.menu > ul > li > div a{font-size: 16px;}
	header.header div.header_right div.langs_social > ul > li div.dropdown{padding-right: 0;}
	div.langs_social{text-align: center;}
	
	section.slider div.slider_content div.slider_text div.slider_dots{padding-right: 29%;}
	section.home_title div.title_holder ul li:first-child{width: 38%;}
	section.home_title div.title_holder ul li:last-child{width: 62%;}
	
	section.home_title div.title_holder.contact_title:before{top: 102%;}
	section.home_title div.title_holder.contact_title:after{top: 102%;}
	
	section.home_title div.hand_holder{width: 490px; left: 71%;}
	
	section.news a.more2{padding: 25px 30px;}
	
	footer.footer div.footer_top div.holder input.input{width: 70%;}
	footer.footer div.footer_top div.holder input.submit{width: 30%;}
	
	section.news article div.desc{padding: 10px;}
	section.download div.desc_links ul li > a{font-size: 18px;}
	
	section.home_title div.title_holder ul li img{top: 10px;}
	
	section.news ul li{width: 31%; margin-right: 3%;}
	section.news ul li:nth-child(3n){margin-right: 0%;}
	section.news ul li:nth-child(4n){margin-right: 3%;}
	
	section.partners div.image div.partners_cover div.default_font h3{font-size: 15px; line-height: 25px;}
	section.partners div.image div.partners_cover a.more3{padding: 27px 56px 29px 31px !important; background-position: right 10px center;}
	
	div.default_font h1{font-size: 36px; line-height: 46px;}
	div.default_font h2{font-size: 30px; line-height: 40px;}
	div.default_font h3{font-size: 26px; line-height: 36px;}
	div.default_font h4{font-size: 20px; line-height: 30px;}
	div.default_font h5{font-size: 18px; line-height: 28px;}
	div.default_font h6{font-size: 16px; line-height: 26px;}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.container{width: 890px;}
	div.logo_box img{width: 120px; height: auto;}
	header.header div.logo_box{left: -52px; top: 38px;}
	header.header div.header_right div.langs_social > ul > li div.dropdown{margin-left: 0; padding-right: 0;}
	header.header div.header_right{padding-top: 70px;}
	header.header div.header_right div.right_holder nav.menu > ul > li{padding-right: 13px; margin-right: 13px;}
	header.header div.header_right div.right_holder nav.menu > ul > li a:after{top: 209%;}
	header.header div.header_right div.right_holder nav.menu > ul > li a{font-size: 15px;}
	div.langs_social{text-align: center;}
	
	section.slider div.slider_content div.slider_text div.slider_dots{padding-right: 29%; top: auto; bottom: 13px;}
	
	section.news article div.desc{padding: 10px;}
	
	section.download div.desc_links ul li > a{font-size: 16px; padding-left: 35px;}
	
	section.home_title div.title_holder ul li img{top: 20px;}
	
	section.news ul li{width: 31%; margin-right: 3%;}
	section.news ul li:nth-child(3n){margin-right: 0%;}
	section.news ul li:nth-child(4n){margin-right: 3%;}
	
	section.partners div.image div.partners_cover div.default_font h3{font-size: 15px; line-height: 25px;}
	section.partners div.image div.partners_cover a.more3{padding: 15px 51px 15px 26px !important; background-position: right 10px center;}
	header.header div.header_right div.right_holder nav.menu > ul > li a{font-size: 12px!important;}
	header.header div.header_right div.right_holder nav.menu > ul > li a:after{top: 242%;}
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	section.home_title div.title_holder.contact_title:before{top: 118%;}
	section.home_title div.title_holder.contact_title:after{top: 118%;}
	
	div.default_font h1{font-size: 36px; line-height: 46px;}
	div.default_font h2{font-size: 30px; line-height: 40px;}
	div.default_font h3{font-size: 26px; line-height: 36px;}
	div.default_font h4{font-size: 20px; line-height: 30px;}
	div.default_font h5{font-size: 18px; line-height: 28px;}
	div.default_font h6{font-size: 16px; line-height: 26px;}
	
	section.home_title div.title_holder ul li:last-child{width: 60%;}
	section.home_title div.title_holder ul li:first-child{width: 40%;}
	section.home_title div.title_holder ul li div.default_font{padding-left: 0;}
	
	section.home_title div.hand_holder img{display: none;}
	section.home_title div.title_holder2{display: none;}
	
	section.news a.more2{padding: 25px 25px;}
	
	footer.footer div.footer_top div.holder input.input{width: 70%;}
	footer.footer div.footer_top div.holder input.submit{width: 30%;}
	
	section.cooks div.desc{display: flex; flex-flow: column;}
	section.cooks div.desc div.image{order: 2; align-self: stretch;}
	section.cooks div.desc div.text_desc{order: 1; align-self: stretch;}
	section.cooks div.desc div.text_desc{padding-bottom: 20px;}
	section.cooks div.desc div.image{width: 100%;}
	section.cooks div.desc div.text_desc{width: 100%!important; padding-left: 0!important; padding-right: 0!important;}
	section.cooks div.desc.desc_right div.image{width: 100%;}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	section.news ul li{width: 48%; margin-right: 3%;}
	section.news ul li:nth-child(2n){margin-right: 0%;}
	section.news ul li:nth-child(3n){margin-right: 3%;}
	section.news ul li:nth-child(4n){margin-right: 0;}
	section.partners div.carousel1 > ul > li{width: 50%;}
	
	section.partners div.partners_segment ul li{width: 50%!important;}
	section.participants div.participants_list li.participants_box{width: 48%!important; margin-right: 4%!important;}
	//section.participants div.participants_list li.participants_box:nth-child(3n){margin-right: 4%;}
	section.participants div.participants_list li.participants_box:nth-child(2n){margin-right: 0!important;}
	
	section.gallery div.gallery_list ul li{width: 48%!important; margin-right: 4%!important; padding-bottom: 41%!important;}
	section.gallery div.gallery_list ul li:nth-child(2n){margin-right: 0!important;}
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
	section.news ul li{width: 100%; margin-right: 0%!important;}
	section.partners div.carousel1 > ul > li{width: 100%;}
	section.partners div.partners_segment ul li{width: 100%!important;}
	
	section.participants div.participants_list li.participants_box{width: 100%!important; margin-right: 0%!important;}
	section.gallery div.gallery_list ul li{width: 100%!important; padding-bottom: 82%!important; margin-right: 0!important; margin-bottom: 30px!important;}
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
	div.default_font h1{font-size: 36px; line-height: 46px;}
	div.default_font h2{font-size: 30px; line-height: 40px;}
	div.default_font h3{font-size: 26px; line-height: 36px;}
	div.default_font h4{font-size: 20px; line-height: 30px;}
	div.default_font h5{font-size: 18px; line-height: 28px;}
	div.default_font h6{font-size: 16px; line-height: 26px;}
	
	header.section_header{padding-left: 0;}
	
	header.header{padding-top: 10px;}
	header.header div.logo_box{position: relative; left: auto;}
	header.header div.logo_box img{width: 90px; height: auto;}
	div.langs_social{text-align: right;}
	header.header div.header_right div.langs_social > ul > li div.dropdown{margin-left: 0; padding-right: 0;}
	header.header div.header_right div.right_holder .header_contact{display: none;}
	header.header div.header_right{padding-top: 0; border-bottom: none;}
	header.header div.header_right div.right_holder{text-align: center;}
	header.header div.header_right div.right_holder nav.menu > ul > li {width: 100%; margin-bottom: 10px; padding-right: 0; margin-right: 0;}
	header.header div.header_right div.right_holder nav.menu > ul > li a{padding-right: 0; margin-right: 0;}
	header.header div.header_right div.right_holder nav.menu > ul > li a:after{display: none;}
	
	nav.menu{display: none;}
	
	div.rwd_button{width: 30px; position: absolute; top: 30px;; right: 0;}
	div.rwd_button > div{width: 100%; height: 3px; margin-bottom: 3px; background-color: $yellow;}
	div.rwd_button > div:last-child{margin-bottom: 0;}
	
	section.slider div.slider_content div.slider_text div.slider_dots{top:-20px; padding-right: 0;}
	section.slider div.slider_content div.slider_text div.slider_dots > ul{text-align: center;}
	section.slider div.slider_content div.slider_text div.default_font{padding-right: 0; text-align: center;}
	section.slider div.slider_content div.slider_text div.default_font h1:after, section.slider div.slider_content div.slider_text div.default_font h2:after, section.slider div.slider_content div.slider_text div.default_font h3:after, section.slider div.slider_content div.slider_text div.default_font h4:after, section.slider div.slider_content div.slider_text div.default_font h5:after, section.slider div.slider_content div.slider_text div.default_font h6:after, section.slider div.slider_content div.slider_text div.default_font p:after{display: none;}
	
	section.home_title div.title_holder ul li div.default_font{padding-left: 15px; padding-right: 15px;}
	section.home_title div.title_holder2{display: none;}
	section.home_title div.hand_holder{display: none;}
	section.home_title div.title_holder ul li:first-child{display: none;}
	section.home_title div.title_holder ul li:last-child{width: 100%;}
	
	section.contest div.contest_content_holder div.contest_holder{padding-left: 15px; padding-top: 60px;}
	
	section.short_about div.col-md-12 div.short_about_content > div.desc > header{margin-left: 0;}
	section.short_about div.col-md-12 div.short_about_content > div.desc div.text_desc{padding-left: 0; padding-right: 15px; border-right: none; padding-bottom: 30px; padding-top: 0;}
	section.short_about div.col-md-12 div.short_about_content > div{width: 100%;}
	section.short_about div.col-md-12 div.short_about_content > div.image{text-align: center; height: auto; padding-bottom: 0;}
	section.short_about div.col-md-12 div.short_about_content > div.desc > header{border-bottom: none; padding-top: 30px; padding-bottom: 30px;}
	section.short_about div.col-md-12 div.short_about_content > div.image > img{bottom: 0; left: 0; position: relative;}
	
	section.home_bottom{padding: 30px 0;}
	section.home_bottom div.default_font{padding-top: 100px; padding-bottom: 100px; padding-right: 15px; padding-left: 15px; text-align: center;}
	
	section.home_bottom div.default_font h1:after, section.home_bottom div.default_font h2:after, section.home_bottom div.default_font h3:after, section.home_bottom div.default_font h4:after, section.home_bottom div.default_font h5:after, section.home_bottom div.default_font h6:after, section.home_bottom div.default_font p:after{display: none;}
	
	footer.footer div.footer_top{padding: 30px 0;}
	footer.footer div.footer_top div.default_font{padding-bottom: 30px;}
	footer.footer div.footer_top div.holder input.input{width: 100%; padding-left: 0; text-align: center;}
	footer.footer div.footer_top div.holder input.submit{width: 100%;}
	
	footer.footer div.footer_bottom{padding: 30px 0;}
	footer.footer div.footer_bottom div.col-md-4.text-left div.default_font{text-align: center;}
	footer.footer div.footer_bottom ul{text-align: center;}
	footer.footer div.footer_bottom div.col-md-4.text-center p{margin: 15px 0;}
	footer.footer div.footer_top div.holder{margin-left: 0;}
	
	section.top header.page_header{padding-top: 220px;}
	section.home_title div.title_holder.no_border{padding-left: 15px; padding-right: 15px;}
	
	
	section.cooks div.text_desc{width: 100%;}
	section.cooks div.image{width: 100%; text-align: center;}
	section.cooks div.desc div.image{background-position: center top; background-size: cover; width: 100%; height: 0; padding-bottom: 100%;}
	section.cooks div.image img{position: relative; right: auto; left: auto;}
	section.cooks div.desc.desc_right div.image img{position: relative; right: auto; left: auto;}
	section.cooks header.text-right{text-align: left;}
	section.cooks div.desc.desc_right div.image{width: 100%;}
	section.cooks div.container > div.row{margin-bottom: 0;}
	section.cooks div.container > div.row > div.col-md-6{margin-bottom: 30px;}
	section.cooks div.desc{display: flex; flex-flow: column;}
	section.cooks div.desc div.image{order: 2; align-self: stretch;}
	section.cooks div.desc div.text_desc{order: 1; align-self: stretch;}
	section.cooks div.desc div.text_desc{padding-bottom: 20px;}
	
	section.download div.desc_links{padding-left: 0; padding-right: 0;}
	section.download div.desc_links ul li{width: 100%; margin-right: 0; margin-bottom: 10px;}
	
	section.home_title div.title_holder.contact_title{padding: 0 15px;}
	section.contact div.contact_data{padding: 0 15px;;}
	section.contact div.contact_form{padding: 0 15px;;}
	section.home_title div.title_holder.contact_title:before{display: none;}
	section.home_title div.title_holder.contact_title:after{display: none;}
	section.contact div.contact_data{margin-bottom: 30px;}
	section.contact div.contact_form > div.default_font.text-right{text-align: left;}
	
	section.home_title div.title_holder.no_border:before{display: none;}
	section.home_title div.title_holder.no_border:after{display: none;}
	section.news_details div.text_desc{padding-left: 15px; padding-right: 15px; margin-bottom: 30px;}
	section.news_details div.image{text-align: center;}
	section.top header.page_header div.default_font{padding: 0 15px;}
	
	section.news a.more2{margin-right: 0; margin-bottom: 30px;}
	header.header div.header_right div.right_holder nav.menu > ul > li:after{display: none;}
	
	header.header{
		transition: 0.5s all ease;
		&.opened{
			background-color: rgba(0,0,0,0.9);
		}
	}
	
	section.partners div.image div.partners_cover div.default_font h3{font-size: 15px; line-height: 25px;}
	section.partners div.image div.partners_cover a.more3{padding: 15px 51px 15px 26px; background-position: right 10px center;}
	
	section.cooks div.cooks_box{margin-bottom: 0;}
	
	section.part_details div.text_desc{padding: 0 19px 30px 19px!important;}
	section.part_details div.image_box{padding-top: 0!important; padding-right: 0!important;}
}

div.popup1{
	display: none;
	position: fixed;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	background-color: rgba(255,255,255,0.9);
	z-index: 12;
	overflow: scroll;
	div.popup_content{
		border: 4px solid $yellow;
		padding: 30px;
		background-color: $white;
		margin: 30px 0;
		div.desc{
			header{
				border-bottom: 4px solid $yellow;
				padding-bottom: 30px;
				div.default_font{
					h1,h2,h3,h4,h5,h6{
						color: $yellow;
					}
				}
			}
			div.text_desc{
				padding: 30px 30px 0 0;
			}
		}
		div.text-right{
			img{
				cursor: pointer;
			}
		}
		div.image{
			width: 100%;
			height: 0;
			padding-bottom: 100%;
			background-position: center top;
			background-size: cover;
			background-repeat: no-repeat;
		}
	}
}

section.partners{
	padding-top: 34px;
	div.partners_segment{
		position: relative;
		background-color: $white;
		z-index: 5;
		margin-bottom: 50px;
		&:last-child{
			margin-bottom: 0;
		}
		div.image{
			div.partners_cover{
				opacity: 0;
				div.default_font{
					h1,h2,h3,h4,h5,h6,p{
						transition: 0.3s all ease;
					}
				}
				a.more3{opacity: 0; transition: 0.3s all ease; padding: 28px 83px 29px 31px; text-transform: uppercase;}
			}
			&:hover{
				div.partners_cover{
					opacity: 1;
					div.default_font{
						h1,h2,h3,h4,h5,h6,p{
							text-shadow: 0px 0px 30px rgba(0, 0, 0, 1);
						}
					}
					a.more3{opacity: 1;}
				}
			}
		}
		ul{
			li{
				display: inline-block;
				vertical-align: top;
				width: 25%;
				margin-right: -4px;
			}
		}
	}
}

section.participants{
	padding: 37px 0;
	div.participants_list{
		li.participants_box{
			display: inline-block;
			vertical-align: top;
			width: 32.6%;
			margin-right: 1%;
			position: relative;
			&:nth-child(3n){
				margin-right: 0;
			}
			div.image{
				width: 100%;
				height: 0;
				padding-bottom: 80%;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				position: relative;
				a.more3{opacity: 0; transition: 0.3s all ease; background-color: rgba(0,0,0,0.7); padding: 28px 83px 29px 31px; text-transform: uppercase;}
			}
			div.text_desc{
				width: 80%;
				margin-left: auto;
				margin-right: auto;
				position: relative;
				top: -80px;
				> div.default_font{
					padding: 95px 20px 24px 20px;
					border-left: 5px solid $yellow;
					position: relative;
					transition: 0.3s all ease;
					&:after{
						content: '';
						display: block;
						width: 39px;
						border-bottom: 5px solid $yellow;
						position: absolute;
						top: 100%;
						left: -5px;;
					}
				}
			}
			&:hover{
				div.image{
					a.more3{
						opacity: 1;
					}
				}
				div.text_desc{
					div.default_font{
						color: $yellow;
					}
				}
			}
		}
	}
}

section.part_details{
	background-color: $white;
	position: relative;
	z-index: 5;
	div.text_desc{
		padding-top: 60px;
		padding-bottom: 60px;
		padding-left: 50px;
		padding-right: 50px;
		div.default_font{
			color: #545454;
			h1,h2,h3,h4,h5,h6{
				color: $black;
				padding-bottom: 20px;
			}
		}
	}
	div.image_box{
		padding-right: 20px;
		padding-top: 60px;
		div.image{
			width: 100%;
			height: 0;
			padding-bottom: 150%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}
	}
}


section.gallery{
	position: relative;
	z-index: 16;
	padding: 3% 0;
	div.gallery_list{
		ul{
			li{
				display: inline-block;
				vertical-align: top;
				width: 31.3%;
				margin-right: 3%;
				position: relative;
				height: 0;
				padding-bottom: 27.36%;
				overflow: hidden;
				margin-bottom: 3%;
				margin-left: -4px;
				> div.image{
					transition: 0.3s all ease;
					@include fill;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
				}
				&:nth-child(3n){
					margin-right: 0;
				}
				&:hover{
					div.image{
						transform: scale(1.1);
					}
				}
			}
		}
	}
}